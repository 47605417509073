<template>
  <MyView ref="view" :meta="{ viewClass: 'story-visor', bodyClass: `story-visor-body transparent` }" v-swipe="{ down: () => closeStories() }">
    <Overlay class="loading loading-logo __transparent" v-if="loading" />
    <PostMediaSlider
      class="story-visor-slider"
      v-if="hasPosts"
      :isEdit="true"
      ref="mediaSldier"
      @delSlide="closeStories"
      @currentSlide="currentSlide"
      :showInfo="false"
      :settings="slider"
    >
      <template slot="options">
        <a class="button __audio" v-if="hasSound" @click="audio">
          <iconic v-if="muted" name="audio-off" />
          <iconic v-else name="audio" />
        </a>
        <div class="button __timelapse" v-if="hasRealPosts && timeLapse">{{ timeLapse }}</div>
      </template>
      <StoryVisorHeader slot="header" :creator="creator" :posts="posts">
        <template slot="bars">
          <StoryVisorXProgressBar
            v-for="(post, index) in posts"
            :posts="posts"
            :creator="creator"
            :key="post._id"
            :index="index"
            :ref="`bar${post._id}`"
            @onfinish="nextStory"
          />
        </template>
      </StoryVisorHeader>
      <div v-for="(post, index) in posts" :key="post._id" :class="` file story-media-wrap`">
        <div class="story-visor-media">
          <StoryVisorVideo
            v-if="post.media && mediaType(post.media[0]) === 'video'"
            :refs="$refs"
            :ref="`VideoVisor${post._id}`"
            :src="post.media[0].src"
            :poster="post.media[0].thumb"
            :index="index"
            :post="post"
            :hasMedia="hasMedia(post)"
            :muted="muted"
          />
          <StoryVisorImage
            v-else-if="post.media && mediaType(post.media[0]) === 'image'"
            :refs="$refs"
            :ref="`ImageVisor${post._id}`"
            :post="post"
            :src="post.media && post.media[0].src"
            :index="index"
            :hasMedia="hasMedia(post)"
          />
          <StoryVisorText v-else-if="post.message" :refs="$refs" :ref="`TextVisor${post._id}`" :post="post" :index="index" />
        </div>
        <div class="story-visor-dummy">
          <img alt="Image" src="https://myfans1a.s3.amazonaws.com/stories/dummy_bg.png" />
        </div>
      </div>
    </PostMediaSlider>
  </MyView>
</template>

<script>
import MyView from "../../views/View.vue";
import Overlay from "../widgets/Overlay.vue";
import PostMediaSlider from "../widgets/PostMediaSlider.vue";
import StoryVisorVideo from "./StoryVisorVideo.vue";
import StoryVisorHeader from "./StoryVisorHeader.vue";
import StoryVisorImage from "./StoryVisorImage.vue";
export default {
  components: {
    MyView,
    PostMediaSlider,
    Overlay,
    StoryVisorVideo,
    StoryVisorHeader,
    StoryVisorImage,
    StoryVisorText: () => import("./StoryVisorText.vue"),
    StoryVisorXProgressBar: () => import("./StoryVisorXProgressBar.vue"),
  },
  data: function() {
    return {
      loading: true,
      hasPosts: false,
      hasRealPosts: false,
      containsMedia: false,
      hasSound: false,
      muted: true,
      timeLapse: "",
      slider: {
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    closeStories: function() {
      this.setQuery({ story_view: undefined, post: undefined, mediaIndex: undefined });
    },
    scanTypes: function() {
      let types = new Set();
      [].forEach.call(this.posts, (post) => {
        if (post.media && post.media.length) {
          types.add(this.mediaType(post.media[0]));
        } else if (post.message) {
          types.add("text");
        }
      });
      return types;
    },
    currentSlide: async function({ index }) {
      let mediaTypes = this.scanTypes();
      let [VideoVisor, ImageVisor, TextVisor, AllVisors] = [false, false, false, []];

      Object.keys(this.$refs).forEach((key) => {
        if (key.indexOf("VideoVisor") > -1) {
          VideoVisor = true;
          AllVisors.push(key);
        } else if (key.indexOf("ImageVisor") > -1) {
          ImageVisor = true;
          AllVisors.push(key);
        } else if (key.indexOf("TextVisor") > -1) {
          TextVisor = true;
          AllVisors.push(key);
        }
      });

      if (mediaTypes.has("video") && !VideoVisor) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }

      if (mediaTypes.has("image") && !ImageVisor) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }

      if (!this.containsMedia && !TextVisor) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }

      [].forEach.call(AllVisors, (visor) => {
        this.$refs[visor]?.currentSlide({ index });
      });

      this.hasSound = this.posts[index] && this.mediaType(this.posts[index]["media"][0]) === "video";
      //this.timeLapse = this.getTimeLapse({ date: this.posts[index]?.dateReview });
    },
    nextStory: async function({ index }) {
      if (this.currentIndex >= this.posts.length - 1) {
        await this.sleep(300);
        this.closeStories();
      } else {
        this.$refs.mediaSldier.nextSlide();
      }
    },
    endStories: function() {
      this.closeStories();
    },
    hasMedia: function(post) {
      if (post.media.length) {
        this.containsMedia = true;
        return true;
      }
    },
    fillNotExistsPosts: function({ posts = [], profile, emptyPost = {} }) {
      if (posts.length) return posts;
      emptyPost.message = "Stories not found";
      emptyPost.media = [];
      emptyPost.user = profile;
      return [emptyPost];
    },
    getCreatorStories: function() {
      this.isLoading(true);
      let query = `&page=1${this.$route.query.story_view ? `&creator=${this.$route.query.story_view}` : ""}`;
      query += `&type=story`;
      query += `${this.$route.query.post ? `&story=${this.$route.query.post}` : ""}`;
      const search = this.$api.get(`${process.env.VUE_APP_API_HOST}posts/stories-creator?${query}`);
      search.then(async ({ data: { posts, profile, statistics } }) => {
        this.hasRealPosts = posts.length > 0;
        posts = this.fillNotExistsPosts({ posts, profile, statistics });
        this.$store.commit("setStories", { stories: posts, creator: profile, statistics });
        await this.sleep(1000);
        this.currentSlide({ index: 0 });
        this.hasPosts = posts.length > 0;
        this.isLoading(false);
      });
      search.catch((error) => {
        this.ialert.go({ message: error, unique: true, title: "Error" });
        this.isLoading(false);
      });
    },
    audio: function() {
      this.muted = !this.muted;
    },
  },
  mounted: async function() {
    this.getCreatorStories();
  },
  computed: {
    posts: function() {
      return this.$store.getters.stories.stories;
    },
    creator: function() {
      return this.$store.getters.stories.creator;
    },
    statistics: function() {
      return this.$store.getters.stories.statistics;
    },
    currentIndex: function() {
      return this.$store.getters.stories.currentIndex;
    },
  },
};
</script>

<style lang="scss">
.story-visor {
  z-index: 13;
  &-body {
    @include Flex(column, center, center);
    position: relative;
    -webkit-box-pack: start;
    &:before {
      content: "";
      position: fixed;
      width: 100vw;
      height: 100vh;
      @include Glass(#000, 0.6);
    }
  }
  &-dummy {
    height: 100%;
  }
  &-slider {
    .slick-track {
      background: rgba(0, 0, 0, 0);
      height: 100%;
    }
    .slick-slide {
      height: 100%;
      max-height: 100%;
      > div,
      .file-media {
        max-height: 100%;
        height: 100%;
      }
      .story-media-wrap svg,
      .story-media-wrap img,
      .story-media-wrap video {
        width: auto;
        margin: auto;
        max-height: 100vh;
        pointer-events: none;
      }
    }
    .slick-arrow {
      opacity: 0;
    }
  }
  &-message {
    color: #fff;
    font-size: 150%;
    text-shadow: #00000066 0px 1px;
    position: absolute;
    padding: $mpadding * 2;
    bottom: 0;
    left: 0;
    right: 0;
    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      content: "";
      display: block;
      height: 80px;
      left: 0px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      z-index: 0;
    }
  }
  .postMediaSlider {
    height: 100vh;
    &-info {
      .__timelapse {
        font-size: 80%;
      }
    }
  }
  &-body,
  .postMediaSlider,
  .slick-slider,
  .slick-list,
  .slick-slide,
  .story-media-wrap {
    width: auto !important;
    width: 100% !important;
    margin: auto;
    max-width: $pocket-width;
    height: 100%;
  }
  &-media {
    @include Flex(column, center, center);
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .story-media-wrap {
    display: flex !important;
    align-items: center;
  }
  @include screen_pc() {
    .slick-arrow {
      opacity: 1;
    }
    .postMediaSlider {
      max-height: calc(100vh - #{$mpadding * 4});
      @include Glass(#000, 0.3);
      box-shadow: 0 0 $mpadding * 10 #bd090973;
    }
  }
}
</style>
