<template>
  <picture :id="id" class="story-image" :style="style" @mousedown="imageDown" @ontouchstart="imageDown" @mouseup="imageUp" @ontouchend="imageUp">
    <div :class="`story-visor-message`" v-html="cleanHTML(decodeURIComponent(post.message))"></div>
    <CreatorPostSubscribe v-if="post.availableFor === 'subscriber' && post.subcribeStatus !== 'active'" :post="post" />
    <CreatorPostUnlock v-else-if="post.availableFor === 'purchase' && post.subcribeStatus !== 'purchased'" :post="post" />
  </picture>
</template>

<script>
export default {
  components: {
    CreatorPostSubscribe: () => import("../creator/CreatorPostSubscribe.vue"),
    CreatorPostUnlock: () => import("../creator/CreatorPostUnlock.vue"),
  },
  props: ["src", "poster", "index", "post", "refs"],
  data: function() {
    return {
      id: `image-${this.$global.fastID()}`,
      progressBar: null,
      duration: 6000,
      style: `--picture:url(${this.encode_url(this.src)})`,
      image: null,
    };
  },
  methods: {
    imageDown: function() {
      if (!this.progressBar) return;
      this.progressBar.pause();
    },
    imageUp: function() {
      if (!this.progressBar) return;
      this.progressBar.play();
    },
    imageStop: function({ index }) {
      if (!this.progressBar) return;
      if (index > this.index) {
        this.progressBar.complete();
      } else {
        this.progressBar.cancel();
      }
    },
    imageStarted: async function() {
      if (this.progressBar) {
        this.progressBar.start({ duration: this.duration, index: this.index });
      }
    },
    currentSlide: async function({ index }) {
      this.image = document.getElementById(this.id);

      if (!this.image) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }

      this.progressBar = this.refs[`bar${this.post._id}`][0];
      if (index === this.index) {
        this.imageStarted();
      } else {
        this.imageStop({ index });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.story-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--picture);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
