<template>
  <div class="story-header" :style="`--length:${posts.length}`">
    <div class="story-header-bars">
      <slot name="bars"></slot>
    </div>
    <div class="story-header-creator">
      <router-link class="story-header-link" :to="`/${creator.user}`">
        <div class="story-header-picture" :style="`--picture:url(${creator.picture})`"></div>
        <div class="story-header-name">{{ creator.name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["posts", "creator"],
};
</script>

<style lang="scss">
.story-visor {
  .story-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: $mpadding;
    &-bars {
      position: absolute;
      top: $mpadding/2;
      left: $mpadding;
      right: $mpadding;
      display: flex;
      gap: $mpadding/2;
    }
    &-creator {
      display: flex;
      align-items: center;
      gap: $mpadding;
      color: rgb(255, 255, 255);
      position: relative;
      z-index: 1;
      padding: $mpadding/2 0 0 0;
    }
    &-link {
      display: inline-flex;
      align-items: center;
      gap: $mpadding;
    }
    &-picture {
      @include BgImage();
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-image: var(--picture);
    }
    &::before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      content: "";
      display: block;
      height: 80px;
      left: 0px;
      position: absolute;
      top: 0px;
      width: 100%;
      z-index: 0;
    }
  }
  .postMediaSlider-info {
    top: $mpadding * 2;
  }
}
</style>
