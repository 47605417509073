<template>
  <div class="story-video" @mousedown="videoDown" @mouseup="videoUp" @ontouchend="videoUp" @ontouchstart="videoDown">
    <video width="100%" controls="hidden" playsinline :poster="poster" :id="id" :muted="muted" preload="auto" controlsList="nodownload">
      <source :src="`${encode_url(src)}`" />
    </video>
    <div :class="`story-visor-message`" v-html="cleanHTML(decodeURIComponent(post.message))"></div>
    <CreatorPostSubscribe v-if="post.availableFor === 'subscriber' && post.subcribeStatus !== 'active'" :post="post" />
    <CreatorPostUnlock v-else-if="post.availableFor === 'purchase' && post.subcribeStatus !== 'purchased'" :post="post" />
  </div>
</template>

<script>
export default {
  components: {
    CreatorPostSubscribe: () => import("../creator/CreatorPostSubscribe.vue"),
    CreatorPostUnlock: () => import("../creator/CreatorPostUnlock.vue"),
  },
  props: ["src", "poster", "index", "post", "refs", "muted"],
  data: function() {
    return {
      id: `video-${this.$global.fastID()}`,
      progressBar: null,
      video: null,
    };
  },
  methods: {
    videoDown: function() {
      this.video.pause();
      if (!this.progressBar) return;
      this.progressBar.pause();
    },
    videoUp: function() {
      this.video.play();
      if (!this.progressBar) return;
      this.progressBar.play();
    },
    videoStart: function() {
      this.video.currentTime = 0;
      this.video.play();
    },
    videoStop: function({ index }) {
      this.video.currentTime = 0;
      this.video.pause();
      if (!this.progressBar) return;
      if (index > this.index) {
        this.progressBar.complete();
      } else {
        this.progressBar.cancel();
      }
    },
    videoStarted: async function() {
      if (this.progressBar) {
        this.videoStart();
        this.progressBar.start({ duration: this.video.duration * 1000, index: this.index });
      }
    },
    currentSlide: async function({ index }) {
      this.video = document.getElementById(this.id);

      if (!this.video) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }

      this.progressBar = this.refs[`bar${this.post._id}`][0];
      if (index === this.index) {
        this.videoStarted({ index });
      } else {
        this.videoStop({ index });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.story-video {
  video::-webkit-media-controls {
    display: none;
  }
}
</style>
